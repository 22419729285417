"use client";

import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import { searchTenants } from "./actions";

export default function TenantSearch() {
  const [query, setQuery] = useState("");

  return (
    <div className="w-full max-w-7xl px-4 relative">
      <div className="relative">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Search for tenants..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <svg
            className="h-5 w-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>
      {query && query.length > 0 && <SearchResults query={query} />}
    </div>
  );
}

function SearchResults({ query }: { query: string }) {
  const [tenants, setTenants] = useState<{ id: string; name: string }[]>([]);
  const [error, setError] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const fetchResults = useCallback(async () => {
    const result = await searchTenants({
      page: 1,
      itemsPerPage: 10,
      search: query,
    });
    if (result.error) {
      setError(result.error);
    } else {
      setTenants(result.data.docs);
    }
  }, [query]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!tenants.length) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex((prev) => (prev + 1) % tenants.length);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex((prev) => (prev - 1 + tenants.length) % tenants.length);
          break;
        case 'Enter':
          e.preventDefault();
          if (tenants[selectedIndex]) {
            window.location.href = `/${tenants[selectedIndex].id}`;
          }
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [tenants, selectedIndex]);

  if (error) {
    switch (error.name) {
      case "ForbiddenError":
        return <div>Forbidden</div>;
      case "UnexpectedError":
        return <div>Unexpected error</div>;
    }
  }

  if (!tenants) {
    return <div>Loading...</div>;
  }

  return (
    <div className="absolute top-full left-0 right-0 mt-1 z-50">
      <div className="w-full max-w-7xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200">
        <div className="divide-y divide-gray-200">
          {tenants.length === 0 ? (
            <div className="p-4 text-center text-gray-500">
              No results found
            </div>
          ) : (
            tenants.map((tenant, index) => (
              <Link
                key={tenant.id}
                href={`/${tenant.id}`}
                className={`block p-4 hover:bg-gray-50 transition duration-150 ${
                  index === selectedIndex ? 'bg-gray-100' : ''
                }`}
              >
                <div className="flex items-center space-x-4">
                  <div className="flex-1">
                    <h3 className="text-lg font-medium text-gray-900">
                      {tenant.name}
                    </h3>
                    <p className="text-sm text-gray-500">ID: {tenant.id}</p>
                  </div>
                  <svg
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
